<template>
  <div>
    <h3 ref="intro">
      Circumference of a Circle
    </h3>
    <p>
      The circumference of a circle is defined as the distance along the periphery of the circle.
      Imagine you have to walk along the periphery of the circle until you get back to the same point.
      Then the distance travelled is equal to the circumference of the circle.
    </p>
    <h3 ref="types">
      Circumference and Radius of a Circle
    </h3>
    <p>
      There is a straightforward relation between the circumference \(L\) of a circle and its radius \(R\):
      $$L = 2 \pi R$$
    </p>
    <p>
      This relation suggests that the circumference of a circle is directly proportional to its radius.
      In other words &mdash; the larger the radius of a circle, the larger the circumference.
    </p>
    <h3 ref="pg">
      MagicGraph | Measuring the Circumference of a Circle
    </h3>
    <p> Imagine you are given a circle as shown in the MagicGraph below. </p>
    <ul class="a">
      <li> Mark a point on the periphery of this circle where it touches the floor. In the MagicGraph below, this point is shown by a bright red dot. </li>
      <li> Note the location of the bright red dot on the floor. Let's label this location as 'A'. </li>
      <li> Next, roll this circle on the floor along a straight line until the bright red dot touches the floor again. </li>
      <li>
        Note the location of the bright red dot on the floor. Let's label this location as 'B'. Then, the circumference of the circle is distance AB.
      </li>
    </ul>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Circumference',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Circumference of a Circle';
    this.$store.commit('navigation/changeTitle', title);
    /*
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Integers', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Integers on a Number Line', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    */
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Circumference of a Circle',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {vmid:'description', name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
            },
}
</script>
